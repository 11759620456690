import MultiStepFormLayout from "@/components/layout/MultiStepLayout";
import { DisplayTitle } from "@/components/stepper/DisplayTitle";
import { MultiForm } from "@/components/stepper/MultiForm";

export default function MultiStepForm() {
  return (
    <MultiStepFormLayout>
      <div className="rounded-xl overflow-visible border text-card-foreground shadow dark:border-gray-800 mx-6 flex flex-col gap-2 bg-white p-6 dark:bg-gray-800 px-4 lg:col-span-2 lg:col-start-2 lg:row-span-3 lg:row-start-2 lg:mx-0 lg:-mt-0 lg:px-16 lg:py-0 lg:shadow-none">
        <DisplayTitle />
        <MultiForm />
      </div>
    </MultiStepFormLayout>
  );
}
