/* eslint-disable @typescript-eslint/no-explicit-any */
import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import AddressCell from "@/components/misc/AddressCell";
import DataTable from "@/components/table/CustomDataTable";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { BLOCKCHAIN_NETWORKS, EXCHANGE_NETWORKS } from "@/config/general";
import {
  downloadCSV,
  formatArrayLongDate,
  formatBalance,
  getNameBySymbol,
} from "@/lib/utils";
import { downloadReportGroup } from "@/services/reportService";
import useReportsStore from "@/stores/reports.store";
import { IconsList } from "@/types/tables.type";
import { ArrowLeft, Clock, Download, Eye } from "lucide-react";
import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatStatus } from "./Reports";
import { ApiTransactionsPayload, Report } from "@/types/report.type";
import { useApiWebSocketCtx } from "@/context/context";
import { useToast } from "@/components/ui/use-toast";

function ReportEntries() {
  const navigate = useNavigate();
  const { socket } = useApiWebSocketCtx();
  const { toast } = useToast();

  const { reportGroupId } = useParams();

  const { selectedReportGroupData, setSelectedReportData } = useReportsStore();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [reportEntries, setReportEntries] = useState<Report[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(1);

  const colsBALA = [
    {
      id: "entry.address",
      name: "Address",
      width: 165,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => <AddressCell fullAddress={row} />,
    },
    {
      id: "entry.network",
      name: "Network",
      width: 10,
    },
    {
      id: "entry.asset",
      name: "Asset",
      width: 10,
    },
    {
      id: "progress",
      name: "Progress",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => {
        const roundedProgress = Math.round(row);
        return (
          <div className="w-1/2 text-center">
            <Progress value={roundedProgress} />
            {roundedProgress} %
          </div>
        );
      },
      width: 10,
    },
    {
      id: "status",
      name: "Status",
      width: 10,
      cell: (row: string) => formatStatus(row),
    },
    {
      id: "result",
      name: "Asset Balance",
      width: 80,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => {
        if (Array.isArray(row) && row.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const balance = row.reduce((acc: number, item: any) => {
            return acc + (item.balance || 0);
          }, 0);
          const formattedBalance = formatBalance(balance);
          const assetName = row[0].asset || "";
          return `${formattedBalance} ${assetName}`;
        } else {
          return `0.00`;
        }
      },
    },
    {
      id: "result",
      name: "Balance Value",
      width: 80,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => {
        if (Array.isArray(row) && row.length > 0) {
          console.log("Thsi .. . .  ", row);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const total = row.reduce((acc: number, item: any) => {
            if (item.price && item.balance) {
              return acc + item.price * item.balance;
            }
            return acc;
          }, 0);
          const formattedTotal = formatBalance(total);
          const currency = row[0].currency || "";
          return `${formattedTotal} ${currency}`;
        } else {
          return `0.00`;
        }
      },
    },
  ];

  const colsEXCH = [
    {
      id: "entry.network",
      name: "Network",
    },
  ];

  const colsPUBK = [
    {
      id: "entry.network",
      name: "Network",
      width: 150,
    },
    {
      id: "Result.value",
      name: "Status",
      width: 200,
      cell: (row: string) => formatStatus(row),
    },
  ];

  const colsRISK = [
    {
      id: "entry.address",
      name: "Address",
      width: 600,
      cell: (row: string) => <AddressCell fullAddress={row} />,
    },
    {
      id: "entry.network",
      name: "Network",
      width: 150,
    },
    {
      id: "result.sanctioned",
      name: "Sanctioned",
      width: 200,
    },
    {
      id: "result.score",
      name: "Score",
      width: 150,
    },
  ];

  const colsSIGN = [
    {
      id: "entry.pubkey",
      name: "Public Key",
      width: 150,
      cell: (row: string) => <AddressCell fullAddress={row} />,
    },
    {
      id: "entry.network",
      name: "Network",
      width: 50,
    },
    {
      id: "status",
      name: "Status",
      width: 20,
      cell: (row: string) => formatStatus(row),
    },
    {
      id: "progress",
      name: "Progress",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => {
        const roundedProgress = Math.round(row);
        return (
          <div className="w-1/2 text-center">
            <Progress value={roundedProgress} />
            {roundedProgress} %
          </div>
        );
      },
      width: 10,
    },
    {
      id: "result.match",
      name: "Match",
      width: 20,
      cell: (row: boolean) => formatStatus(row),
    },
    {
      id: "result.method",
      name: "Method",
      width: 20,
    },
    {
      id: "result.submethod",
      name: "Sub Method",
      width: 20,
    },
    {
      id: "entry.message",
      name: "Message",
      width: 400,
    },
    {
      id: "entry.signature",
      name: "Signature",
      width: 200,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: string) => <AddressCell fullAddress={row} />,
    },
  ];

  const colsMap: { [key: string]: { id: string; name: string }[] } = {
    BALA: colsBALA,
    EXCH: colsEXCH,
    PUBK: colsPUBK,
    RISK: colsRISK,
    SIGN: colsSIGN,
  };

  const filtersMap: {
    [key: string]: { accessorKey: string; placeholder: string };
  } = {
    BALA: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
    EXCH: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
    PUBK: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
    RISK: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
    SIGN: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
  };
  const getReportEntriesForGroup = useReportsStore(
    (state) => state.fetchReportEntriesForSelectedGroup
  );
  const loading = useReportsStore((state) => state.loading);

  const setSelectedReportGroup = useReportsStore(
    (state) => state.setSelectedReportGroup
  );

  const fetchReportEntriesForGroup = useCallback(
    async (reportGroupId: string, page: number, limit: number) => {
      const response = await getReportEntriesForGroup(
        reportGroupId,
        limit,
        page
      );
      if (response && response.data && response.data.length > 0) {
        setReportEntries(response?.data);
        setTotalPages(response.pages);
        setTotalRows(response.max);
        if (response.limit) setPageSize(response.limit);
      }
    },
    [getReportEntriesForGroup]
  );

  useEffect(() => {
    const reportGroup = reportGroupId ? reportGroupId : "";
    fetchReportEntriesForGroup(reportGroup, currentPage, pageSize);
  }, [reportGroupId, pageSize, fetchReportEntriesForGroup, currentPage]);

  useEffect(() => {
    if (reportGroupId) {
      setSelectedReportGroup(reportGroupId || "");
    }
  }, [reportGroupId, setSelectedReportGroup]);

  useEffect(() => {
    if (!socket || !reportGroupId) {
      console.log("Socket or reportGroupId not available yet");
      return;
    }

    const handleReportRunEntries = (data: ApiTransactionsPayload<Report>) => {
      setReportEntries(data?.data); // Update the report entries
      setTotalPages(data.page || 1); // Update pagination data
      setTotalRows(data.max || 100);

      toast({
        title: "Real-time Update",
        description: "Report entries have been updated.",
      });
    };

    const handleReportEntriesError = (error: string) => {
      toast({
        title: "Error",
        description: `Failed to load report entries: ${error}`,
        variant: "destructive",
      });
    };

    socket.on("reportRunEntries", handleReportRunEntries);
    socket.on("reportEntriesError", handleReportEntriesError);

    return () => {
      socket.off("reportRunEntries", handleReportRunEntries);
      socket.off("reportEntriesError", handleReportEntriesError);
    };
  }, [socket, toast, reportGroupId]);

  // const selectedReportGroup = useReportsStore((state) =>
  //   state.reportGroups.find((sg) => sg.reportGroupId === reportGroupId)
  // );

  const cols = selectedReportGroupData?.groupType
    ? colsMap[selectedReportGroupData.groupType]
    : [];
  const filter = selectedReportGroupData?.groupType
    ? filtersMap[selectedReportGroupData.groupType]
    : { accessorKey: "", placeholder: "" };

  const cellIcons: IconsList[] = (
    selectedReportGroupData && selectedReportGroupData.groupType === "EXCH"
      ? EXCHANGE_NETWORKS
      : BLOCKCHAIN_NETWORKS
  ).map((network) => ({
    value: network.value,
    img: network.img,
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDownload = async (row: any) => {
    const response = await downloadReportGroup(row.ReportID);
    downloadCSV(response.data.data, `${row.reportName}.csv`);
  };

  const getRowActions = (groupType: string) => {
    const baseActions = [];

    const isReportSuccess =
      selectedReportGroupData && selectedReportGroupData.status === "S";

    if (["BALA"].includes(groupType)) {
      baseActions.push({
        text: "View",
        onClick: (row: Report) => {
          setSelectedReportData(row);
          row.status !== "P" &&
            row.status !== "F" &&
            navigate(`/reports/${reportGroupId}/${row.reportId}`);
        },
        icon: Eye,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        disabled: (row: any) => row.status === "P" || row.status === "F",
      });
    }

    if (groupType === "PUBK" && isReportSuccess) {
      baseActions.push({
        text: "Download",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick: (row: any) => {
          handleDownload(row);
        },
        icon: Download,
      });
    }

    if (groupType === "EXCH" && isReportSuccess) {
      baseActions.push({
        text: "Download",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick: async (row: any) => {
          handleDownload(row);
        },
        icon: Download,
      });
    }

    return baseActions;
  };

  const snapshotFirstEntryDates: number[] =
    reportEntries && Array.isArray(reportEntries)
      ? reportEntries[0]?.entry?.datetime
      : [Number(Date.now())];
  const snapshotDate = formatArrayLongDate(snapshotFirstEntryDates);

  const handlePageChange = (page: number) => {
    const validPage = !isNaN(page) ? page : 2;
    setCurrentPage(validPage);
  };

  const handlePageSizeChange = (size: number) => {
    const validSize = !isNaN(size) && size > 0 ? size : 100;
    setPageSize(validSize);
  };

  return (
    <div>
      <section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-1 lg:py-2 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-pie-chart h-4 w-4"
          >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>
          <span className="underline-offset-4">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <Link to="/reports">Reports</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>
                    {selectedReportGroupData?.reportName || "Unnamed Report"}
                  </BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </span>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            <h1 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl lg:leading-[1.1]">
              Report: {selectedReportGroupData?.reportName || "Unnamed Report"}
            </h1>
          </div>
          <Button
            className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-8 rounded-md px-3 text-xs ml-auto"
            title="Go Back to Report Entries"
            variant="outline"
            onClick={() => navigate("/reports")}
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Reports
          </Button>
        </div>
        <div className="flex items-center bg-accent">
          <span className="ml-0 border px-2 rounded-full text-sm">
            {getNameBySymbol(selectedReportGroupData?.groupType || "")}
          </span>
          <p className="text-md font-semibold text-gray-800 dark:text-gray-100 ml-4">
            Status:
          </p>
          <span className="ml-2">
            {formatStatus(
              selectedReportGroupData?.status || "",
              selectedReportGroupData?.message || ""
            )}
          </span>

          {["BALA", "EXCH"].includes(
            selectedReportGroupData?.groupType || ""
          ) &&
            snapshotDate && (
              <span className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs">
                <span className="relative group inline-flex items-center">
                  <Clock className="h-4 w-4 m-2" />
                  <span className="absolute left-0 top-full mt-1 px-2 py-1 text-xs text-white bg-gray-800 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    Snapshot Timestamp
                  </span>
                </span>{" "}
                {snapshotDate}
              </span>
            )}
        </div>
        {["R", "P"].includes(selectedReportGroupData?.status || "") && (
          <div>
            <p className="text-xl font-semibold text-gray-800 my-2">
              Progress:
            </p>
            <div className="text-center">
              <Progress
                value={Number(selectedReportGroupData?.progress || 0)}
              />
              {selectedReportGroupData?.progress?.toString() || "0"} %
            </div>
          </div>
        )}
      </section>
      <DashboardMainCard
        header={"Report Entries"}
        description={"Explore Report data entries"}
      >
        <DataTable
          columns={cols}
          columnIcons={[
            { field: "entry.network", icons: cellIcons },
            { field: "entry.asset", icons: cellIcons },
          ]}
          data={reportEntries}
          pagination={{
            currentPage,
            pageSize,
            totalPages,
            totalRows,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
          }}
          loading={loading}
          globalFilter={filter}
          actionButtons={[]}
          rowActions={getRowActions(selectedReportGroupData?.groupType || "")}
        />
      </DashboardMainCard>
    </div>
  );
}

export default ReportEntries;
