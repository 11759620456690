import { SOURCE_PAGE_DESCRIPTION, SOURCE_PAGE_HEADING } from "@/config/general";
import { BarChart, Cog, FileDown, Pencil, Upload } from "lucide-react";
import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import { SourceModal } from "@/components/dialog/SourcesModal";
import DataTable from "@/components/table/CustomDataTable";
import { downloadCSV, getNameBySymbol } from "@/lib/utils";
import { downloadSourceGroup } from "@/services/sourceService";
import useSourcesStore from "@/stores/sources.store";
import Papa from "papaparse";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface SourceGroup {
  sourceGroupId: string;
  sourceName: string;
  groupType: string;
  count: number;
}

function Sources() {
  const navigate = useNavigate();
  const getSourceGroups = useSourcesStore(
    (state) => state.fetchAllSourceGroups
  );
  const sourceLoading = useSourcesStore((state) => state.loading);
  const sourceGroup1 = useSourcesStore((state) => state.sourceGroups);
  const { setSelectedSourceGroupData } = useSourcesStore();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(1);
  const [sourceGroups, setSourceGroups] = useState<SourceGroup[]>([]);
  const [sourceOverlayIsOpen, setSourceOverlayIsOpen] = useState(false);
  const [editSourceId, setEditSourceId] = useState<string | null>(null);

  const fetchSourceGroups = useCallback(
    async (page: number, limit: number) => {
      const response = await getSourceGroups(limit, page);
      if (response && response.data && response.data.length > 0) {
        setSourceGroups(response.data);
        setTotalPages(response.pages);
        setTotalRows(response.max);
        if (response.limit) setPageSize(response.limit);
      }
    },
    [getSourceGroups]
  );

  const cols = [
    { id: "sourceName", name: "Name", width: 200 },
    {
      id: "groupType",
      name: "Type",
      width: 180,
      cell: (value: string) => getNameBySymbol(value),
    },
    { id: "count", name: "Entries", width: 180 },
  ];

  const validateAndCleanCSV = (csvData: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let cleanedData: any[] = [];
    const requiredColumns: string[] = ["ADDRESS", "NETWORK", "ASSET"];

    Papa.parse(csvData, {
      header: true,
      complete: (results) => {
        cleanedData = results.data
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((row: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const cleanedRow: any = {};
            requiredColumns.forEach((col) => {
              cleanedRow[col] = row[col] || ""; // Keep only required columns
            });
            return cleanedRow;
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((row: any) => row.ADDRESS !== "");
      },
    });

    return Papa.unparse(cleanedData);
  };

  useEffect(() => {
    fetchSourceGroups(currentPage, pageSize);
  }, [pageSize, fetchSourceGroups, currentPage]);

  useEffect(() => {
    setSourceGroups(sourceGroup1);
  }, [setSourceGroups, sourceGroup1]);

  useEffect(() => {
    if (!sourceOverlayIsOpen) {
      fetchSourceGroups(currentPage, pageSize);
    }
  }, [sourceOverlayIsOpen, currentPage, pageSize, fetchSourceGroups]);

  const handlePageChange = (page: number) => {
    setCurrentPage(!isNaN(page) ? page : 2);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(!isNaN(size) && size > 0 ? size : 100);
  };

  return (
    <>
      <SourceModal
        isEditDialogOpen={sourceOverlayIsOpen}
        setIsEditDialogOpen={setSourceOverlayIsOpen}
        sourceId={editSourceId}
      />

      <DashboardMainCard
        header={SOURCE_PAGE_HEADING}
        description={SOURCE_PAGE_DESCRIPTION}
      >
        <DataTable
          columns={cols}
          data={sourceGroups}
          loading={sourceLoading}
          globalFilter={{
            placeholder: "Filter Source Names...",
            accessorKey: "sourceName",
          }}
          actionButtons={[
            {
              onClick: () => {
                setEditSourceId(null);
                navigate(`/sources/step`);
              },
              text: "New Source",
              icon: Upload,
            },
          ]}
          pagination={{
            currentPage,
            pageSize,
            totalPages,
            totalRows,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
          }}
          rowActions={[
            {
              text: "Manage",
              icon: Cog,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick: (rowData: any) => {
                setSelectedSourceGroupData(rowData);
                navigate(`/sources/${rowData.sourceGroupId}`);
              },
            },
            {
              text: "Rename",
              icon: Pencil,
              onClick: (rowData) => {
                setEditSourceId(rowData.sourceGroupId);
                setSourceOverlayIsOpen(true);
              },
            },
            {
              text: "Download",
              disabled: (rowData) => rowData.count === 0,
              icon: FileDown,
              onClick: async (rowData) => {
                const response = await downloadSourceGroup(
                  rowData.sourceGroupId
                );
                const cleanCSV = validateAndCleanCSV(response.data.data);
                downloadCSV(cleanCSV, `${rowData.sourceName}.csv`);
              },
            },
            {
              text: "Run Report",
              disabled: (rowData) => rowData.count === 0,
              icon: BarChart,
              onClick: (rowData) => {
                navigate(`/reports/run/${rowData.sourceGroupId}`);
              },
            },
          ]}
        />
      </DashboardMainCard>
    </>
  );
}

export default Sources;
