"use client";
import { useState } from "react";
import StepFour from "@/components/stepper/StepFour";
import { StepSourceEntries } from "@/components/stepper/StepSourceEntries";
import { StepSelectSource } from "@/components/stepper/StepSelectSource";
import { useMultiContext } from "@/context/multistep";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SourceGroupSchema } from "@/zod/source.zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import useAuthStore from "@/stores/auth.store";
import useWorkspaceStore from "@/stores/workspaces.store";
import useSourcesStore from "@/stores/sources.store";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  LoaderCircle,
  CirclePlay,
} from "lucide-react";
import { z } from "zod";
import { StepSourceSummary } from "./StepSourceSummary";
type SourceFormType = z.infer<typeof SourceGroupSchema>;

export function MultiForm() {
  const {
    step,
    nextStep,
    prevStep,
    createSourceGroupForWorkspace,
    sourceEntries,
  } = useMultiContext();
  const { toast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const { selectedSourceGroup } = useSourcesStore();
  const email = useAuthStore((state) => state.user?.email);
  const workspaceId = useWorkspaceStore((state) => state.selectedWorkspaceId);

  const form = useForm<SourceFormType>({
    resolver: zodResolver(SourceGroupSchema),
    defaultValues: {
      groupType: "",
      sourceName: "",
    },
  });

  const handleStepOneSubmit = async (values: SourceFormType) => {
    if (!email || !workspaceId) {
      toast({
        title: "Missing data",
        description: "Email or workspace ID is missing.",
        variant: "destructive",
      });
      return false;
    }

    try {
      setLoading(true);
      const newSourceGroup = {
        sourceGroupId: "",
        sourceName: values.sourceName,
        groupType: values.groupType,
        count: 0,
      };

      createSourceGroupForWorkspace(newSourceGroup);

      toast({
        title: "Source Created",
        description: "You can now add entries to your source.",
      });
      return true; // Indicate success
    } catch (error) {
      toast({
        title: "Error",
        description: `Failed to create source group. ${error}`,
        variant: "destructive",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleStepTwoValidation = () => {
    if (sourceEntries.length === 0) {
      toast({
        title: "No Entries",
        description:
          "You must create at least one source entry before proceeding.",
        variant: "destructive",
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (values: SourceFormType) => {
    console.log("Selected Source Group : ", selectedSourceGroup);
    console.log("Form submitted with values: ", values);

    switch (step) {
      case 1: {
        if (
          selectedSourceGroup &&
          selectedSourceGroup.sourceName === values?.sourceName
        ) {
          nextStep();
        } else {
          const success = await handleStepOneSubmit(values);
          if (success) nextStep();
        }
        break;
      }
      case 2: {
        const valid = handleStepTwoValidation();
        if (valid) {
          nextStep();
        }
        break;
      }

      default: {
        nextStep();
        break;
      }
    }
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 lg:relative lg:flex-1"
      >
        <FormProvider {...form}>
          {step === 1 && <StepSelectSource />}
          {step === 2 && <StepSourceEntries />}
          {step === 3 && <StepSourceSummary />}
          {step === 4 && <StepFour />}
        </FormProvider>
        {step < 5 && (
          <div className="bottom-0 left-0 flex w-full justify-between bg-white dark:bg-gray-800 p-4 lg:bottom-0">
            <Button
              type="button"
              variant={"outline"}
              className={`${step === 1 ? "invisible" : ""}`}
              onClick={() => prevStep()}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button type="submit" disabled={loading}>
              {loading ? (
                <LoaderCircle className="h-4 w-4" />
              ) : step === 4 ? (
                <CirclePlay className="h-4 w-4" />
              ) : (
                <ChevronRightIcon className="h-4 w-4" />
              )}
            </Button>
          </div>
        )}
      </form>
    </Form>
  );
}
