import { FC, useState } from "react";

import { Button } from "@/components/ui/button";
import { LOADING_TEXT } from "@/config/general";
import { getNameBySymbol } from "@/lib/utils";
import useSourcesStore from "@/stores/sources.store";
import { validateAndCleanFile } from "@/zod/source.zod";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";
import { Download, File, UploadCloud } from "lucide-react";
import { useDropzone } from "react-dropzone";
import { useToast } from "../ui/use-toast";

interface SourceUploadFormProps {
  sourceGroupId: string;
  onClose?: () => void;
}

export const SourceUploadForm: FC<SourceUploadFormProps> = ({
  sourceGroupId,
  onClose,
}) => {
  const sourceGroup = useSourcesStore((state) =>
    state.sourceGroups?.find((sg) => sg.sourceGroupId === sourceGroupId)
  );

  console.log("Form Source Group Id   . . . . ", sourceGroupId);
  const [textData, setTextData] = useState("");
  const [fileName, setFileName] = useState("");
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [templateLoading, setTemplateLoading] = useState(false);
  const uploadSource = useSourcesStore((state) => state.uploadSource);
  const downloadTemplate = useSourcesStore((state) => state.downloadTemplate);

  const handleFileChange = (files: File[]) => {
    const file = files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result;
        if (content) {
          setTextData(content as string);
        }
      };
      reader.readAsText(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileChange,
    accept: { "text/csv": [".csv"] },
  });

  if (!sourceGroup) return null;

  const downloadTemplateFile = async () => {
    setTemplateLoading(true);
    const templateResponse: any = await downloadTemplate(sourceGroup.groupType);
    // Create a Blob from the CSV String
    const blob = new Blob([templateResponse.data], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `defaultCSV_${sourceGroup.groupType}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTemplateLoading(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = textData.replace(/\r/g, "");
      const cleanCSV = validateAndCleanFile(data, sourceGroup.groupType);
      await uploadSource(cleanCSV.replace(/\r/g, ""), sourceGroupId);
      setLoading(false);
      toast({
        title: "Success",
        description: "Source uploaded successfully",
      });

      onClose && onClose();
    } catch (e: any) {
      const errMessage = e?.response?.data?.errors[0] || e.message;
      if (errMessage?.includes("Some entries not inserted.")) {
        // take as success
        toast({
          title: "Success",
          description: "Source uploaded successfully",
        });
        onClose && onClose();
        return;
      } else {
        errMessage && setError(errMessage);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-4">
        <span className="font-semibold text-sm">Source Type:</span>
        <span className="text-gray-700 block text-sm border border-gray-300 px-2 py-1 rounded-lg">
          {getNameBySymbol(sourceGroup?.groupType)}
        </span>
        <span>
          <Button
            className="w-full"
            variant="outline"
            disabled={templateLoading}
            onClick={downloadTemplateFile}
          >
            {templateLoading ? (
              <ReloadIcon className="animate-spin" />
            ) : (
              <>
                <Download className="h-4 w-4 mr-2" />
                Download template
              </>
            )}
          </Button>
        </span>
      </div>
      <div className="py-4">
        <Label htmlFor="file">Upload CSV</Label>
        <div
          className="flex items-center justify-center w-full text-gray-500"
          {...getRootProps()}
        >
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            {isDragActive ? (
              <div>Select file</div>
            ) : fileName !== "" ? (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <File className="w-8 h-8 mb-4 dark:text-gray-400" />
                <p className="mb-2 text-sm dark:text-gray-400">{fileName}</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <UploadCloud className="w-8 h-8 mb-4 dark:text-gray-400" />
                <p className="mb-2 text-sm dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs dark:text-gray-400">CSV files only</p>
              </div>
            )}
            <input {...getInputProps()} />
          </label>
        </div>
      </div>
      <div className="text-center">
        {error && (
          <div className="text-red-500 text-sm font-semibold">{error}</div>
        )}
      </div>
      <Button className="w-full mt-4" size="lg" onClick={onSubmit}>
        {loading ? (
          <>
            <span className="loading-icon" />
            {LOADING_TEXT}
          </>
        ) : (
          "Upload"
        )}
      </Button>
    </div>
  );
};
