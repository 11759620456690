import { useMultiContext } from "@/context/multistep";

interface StepItemProps {
  infos: {
    num: number;
    title: string;
    description: string;
  };
}

export function StepItem({ infos }: StepItemProps) {
  const { step } = useMultiContext();
  const maxStep = 5;
  const isFinished = step === maxStep && infos.num === maxStep - 1;

  return (
    <div className="relative z-10 flex flex-col items-center">
      <span
        className={`flex w-10 h-10 lg:w-12 lg:h-12 items-center justify-center rounded-full border-2 font-medium ${
          step === infos.num || isFinished
            ? "border-black bg-gray-950 text-white dark:text-black dark:bg-gray-200 dark:text"
            : "border-gray-500 text-black dark:text-white"
        }`}
      >
        {infos.num}
      </span>

      {/* Step Info */}
      <div className="text-center mt-2">
        <p className="text-sm text-black dark:text-white">{infos.title}</p>
      </div>
    </div>
  );
}
