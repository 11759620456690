"use client";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Input } from "@/components/ui/input";
import { useFormContext, useWatch } from "react-hook-form";
import useSourcesStore from "@/stores/sources.store";
import { NetworkItem } from "@/types/sources.type";
import IconMapper from "./IconMapper";
import { Key } from "react";

export function StepSelectSource() {
  const networks = useSourcesStore((state) => state.networks);
  const sourceTypes = networks.ENTRY_TYPES;
  const { control } = useFormContext();

  const selectedSourceType = useWatch({
    control,
    name: "groupType",
  });

  return (
    <FormField
      control={control}
      name="groupType"
      render={({ field }) => (
        <FormItem className="lg:flex lg:flex-col lg:gap-5">
          <FormControl className="lg:grid lg:h-72 lg:grid-cols-3 mb-8">
            <RadioGroup onValueChange={field.onChange} value={field.value}>
              {sourceTypes.map(
                (sourceType: NetworkItem, i: Key | null | undefined) => (
                  <FormItem key={i}>
                    <FormControl>
                      <RadioGroupItem
                        value={sourceType.symbol}
                        className="peer sr-only"
                      />
                    </FormControl>
                    <FormLabel className="text-sm border dark:border-gray-700 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex flex-col items-center justify-between rounded-md border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-muted-foreground [&amp;:has([data-state=checked])]:border-primary">
                      <IconMapper type={sourceType.symbol} />
                      <div className="flex flex-col font-medium ">
                        <p className="text-lg dark:text-white">
                          {sourceType.name}
                        </p>
                      </div>
                    </FormLabel>
                  </FormItem>
                )
              )}
            </RadioGroup>
          </FormControl>

          {selectedSourceType && (
            <div className="flex items-center justify-center rounded-sm p-2 w-full lg:w-96">
              <FormField
                control={control}
                name="sourceName"
                render={({ field }) => (
                  <FormItem className="w-full lg:w-96">
                    <FormLabel>Source Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter source name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </FormItem>
      )}
    />
  );
}
