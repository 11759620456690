"use client";
import { useEffect, useState } from "react";
import { Plus, Upload, Pencil, Trash } from "lucide-react";
import DataTable from "@/components/table/CustomDataTable";
import { SourceEntryModal } from "@/components/stepper/modals/SourceEntryModal";
import useSourcesStore from "@/stores/sources.store";
import { NetworksData, Source } from "@/types/sources.type";
import { SourceUploadModal } from "@/components/stepper/modals/SourceEntriesUploadModal";
import { SourceUploadPopover } from "@/components/stepper/modals/PopoverUploadSourceEntries";
import { ConfirmDialog } from "@/components/dialog/ConfirmDialog";
import { useMultiContext } from "@/context/multistep";
import { generateCellIcons } from "@/lib/utils";

const filtersMap: {
  [key: string]: { accessorKey: string; placeholder: string };
} = {
  BALA: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  EXCH: { accessorKey: "entry.network", placeholder: "Filter Exchanges..." },
  PUBK: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
  RISK: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  SIGN: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
};

export function StepSourceEntries() {
  const {
    currentPage,
    totalPages,
    totalRows,
    pageSize,
    sourceEntries,
    handlePageChange,
    handlePageSizeChange,
    fetchSourceEntries,
    deleteEntry,
  } = useMultiContext();

  const { selectedSourceGroup, selectedSourceGroupId, setSelectedSource } =
    useSourcesStore();
  const [isUploadOverlayOpen, setIsUploadOverlayOpen] = useState(false);
  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
  const [deleteEntryDialogOpen, setDeleteEntryDialogOpen] = useState(false);
  const [loading, networks] = useSourcesStore((state) => [
    state.loading,
    state.networks,
  ]);

  const [editSourceEntryId, setEditSourceEntryId] = useState<string | null>(
    null
  );
  const sourceGroupId = selectedSourceGroupId || "";
  console.log("Source Groupd ID ", selectedSourceGroupId);
  const cols = [
    { id: "entry.address", name: "Address", width: 400 },
    { id: "entry.network", name: "Network", width: 150 },
    { id: "entry.asset", name: "Asset", width: 150 },
  ];

  useEffect(() => {
    if (sourceGroupId) {
      fetchSourceEntries(sourceGroupId, currentPage, pageSize);
    }
  }, [sourceGroupId, currentPage, pageSize, fetchSourceEntries]);

  const selectedGroupType =
    selectedSourceGroup?.groupType as keyof NetworksData;
  console.log("SELC . .... ", selectedGroupType);
  const cellIcons = generateCellIcons(networks, selectedGroupType);

  const filter = selectedSourceGroup
    ? filtersMap[selectedSourceGroup.groupType]
    : undefined;
  return (
    <div>
      {/* Confirm delete dialog */}
      <ConfirmDialog
        title="Delete Entry"
        description="Are you sure you want

 to delete this entry?"
        open={deleteEntryDialogOpen}
        setOpen={setDeleteEntryDialogOpen}
        severity="error"
        onConfirm={() => {
          deleteEntry(sourceGroupId);
        }}
      />
      <SourceEntryModal
        isEditDialogOpen={isEditOverlayOpen}
        setIsEditDialogOpen={setIsEditOverlayOpen}
        sourceEntryId={editSourceEntryId}
        sourceType={selectedSourceGroup?.groupType}
      />

      {sourceEntries && sourceEntries.length > 0 ? (
        <>
          <DataTable
            columns={cols}
            columnIcons={[
              { field: "entry.network", icons: cellIcons },
              { field: "entry.asset", icons: cellIcons },
            ]}
            data={sourceEntries}
            loading={loading}
            globalFilter={filter}
            actionButtons={[
              {
                onClick: () => {
                  const handleImport = (event: any) => {
                    event?.preventDefault();
                    setIsUploadOverlayOpen(true);
                  };
                  handleImport(event);
                },
                text: "Import CSV",
                icon: Upload,
              },
              {
                onClick: () => {
                  const handleEdit = (event: any) => {
                    event?.preventDefault();
                    setIsEditOverlayOpen(true);
                    setEditSourceEntryId(null);
                  };
                  handleEdit(event);
                },
                text: "Add Entry",
                icon: Plus,
              },
            ]}
            pagination={{
              currentPage,
              pageSize,
              totalPages,
              totalRows,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
            }}
            rowActions={[
              {
                onClick: (rowData: Source) => {
                  setSelectedSource(rowData);
                  setEditSourceEntryId(rowData.sourceId);
                  setIsEditOverlayOpen(true);
                },
                text: "Edit",
                icon: Pencil,
              },
              {
                onClick: async () => {
                  setDeleteEntryDialogOpen(true);
                },
                text: "Delete",
                icon: Trash,
                variant: "danger",
              },
            ]}
          />

          <SourceUploadModal
            sourceGroupId={sourceGroupId}
            isOpen={isUploadOverlayOpen}
            setIsOpen={setIsUploadOverlayOpen}
          />
        </>
      ) : (
        <>
          <div className="flex shrink-0 items-center justify-center rounded-md border border-dashed">
            <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="h-10 w-10 text-muted-foreground"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="11" r="1"></circle>
                <path d="M11 17a1 1 0 0 1 2 0c0 .5-.34 3-.5 4.5a.5.5 0 0 1-1 0c-.16-1.5-.5-4-.5-4.5ZM8 14a5 5 0 1 1 8 0"></path>
                <path d="M17 18.5a9 9 0 1 0-10 0"></path>
              </svg>
              <h3 className="mt-4 text-lg font-semibold">No Entries added</h3>
              <p className="mb-4 mt-2 text-sm text-muted-foreground">
                You have not added any source entries. Add one below.
              </p>
              <div className="p-6 pt-0 grid gap-6">
                <div
                  aria-required="false"
                  dir="ltr"
                  className="grid grid-cols-3 gap-4"
                >
                  <SourceUploadPopover
                    sourceGroupId={sourceGroupId}
                    isOpen={isUploadOverlayOpen}
                    setIsOpen={setIsUploadOverlayOpen}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
