import { FC } from "react";
import { GeneralPopover } from "./GeneralPopover";
import { SourceUploadForm } from "./SourceUploadForm";

interface SourceUploadModalProps {
  sourceGroupId: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSuccess?: () => void;
}

export const SourceUploadPopover: FC<SourceUploadModalProps> = ({
  sourceGroupId,
  isOpen,
  setIsOpen,
  onSuccess,
}) => {
  console.log(": >>>> Source group id", sourceGroupId);
  return (
    <GeneralPopover
      title={"Upload Source"}
      triggerText="Open Upload"
      description={
        "Upload a source to the selected source group using a CSV file"
      }
      isEditPopoverOpen={isOpen}
      setIsEditPopoverOpen={setIsOpen}
    >
      <SourceUploadForm
        sourceGroupId={sourceGroupId}
        onClose={() => {
          setIsOpen(false);
          if (onSuccess) {
            onSuccess();
          }
        }}
      />
    </GeneralPopover>
  );
};
