import { ReactNode, useEffect, useRef, useState } from "react";
import { User } from "@/types/auth.type";
import { io } from "socket.io-client";
import { ApiWebSocketCtx, CustomApiWebSocket } from "./context";
import { DownloadResponse } from "@/types/report.type";

// Provider component
const ApiWebSocketCtxProvider = ({ children }: { children?: ReactNode }) => {
  const socketRef = useRef<CustomApiWebSocket | null>(null); // Start socket as null initially
  const [socket, setSocket] = useState<CustomApiWebSocket | null>(null); // Track socket state
  const [isSocketConnected, setIsSocketConnected] = useState(false); // Track connection state
  const user: User | null = JSON.parse(
    sessionStorage.getItem("user") || "null"
  );
  const token = user?.token;

  useEffect(() => {
    if (!socketRef.current) {
      const socketInstance: CustomApiWebSocket = io(
        import.meta.env.VITE_API_BASE_WS,
        {
          transports: ["websocket", "polling"],
          withCredentials: true,
          reconnectionAttempts: 5,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          autoConnect: true,
          auth: { token },
        }
      ) as CustomApiWebSocket;

      socketRef.current = socketInstance;
      setSocket(socketInstance);

      socketInstance.on("connect", () => {
        setIsSocketConnected(true);
        console.log("Connected to WebSocket with ID:", socketInstance.id);
      });

      socketInstance.on("disconnect", () => {
        setIsSocketConnected(false);
        console.log(":: Disconnected from WebSocket");
      });

      socketInstance.on("connect_error", (err: Error) => {
        console.error("WebSocket connection error: ", err.message);
      });

      socketInstance.on("reportDownload", (data: DownloadResponse) => {
        console.log("Report downloaded:", data);
      });

      // Cleanup listeners when the component unmounts
      return () => {
        console.log("Cleaning up WebSocket listeners...");
        socketInstance.off("connect");
        socketInstance.off("disconnect");
        socketInstance.off("connect_error");
        socketInstance.off("reportRunEntries");
        socketInstance.off("reportDownload");
        socketInstance.disconnect();
      };
    }
  }, []);

  return (
    <ApiWebSocketCtx.Provider value={{ socket, isSocketConnected }}>
      {children}
    </ApiWebSocketCtx.Provider>
  );
};

export default ApiWebSocketCtxProvider;
