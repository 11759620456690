import {
  createSourceEntry,
  createSourceGroup,
  deleteSourceEntry,
  downloadSourceGroup,
  downloadTemplate,
  editSourceEntry,
  editSourceGroup,
  getAllSourceGroups,
  getSourcesPage,
  selectSourceGroup,
  uploadSource,
} from "../services/sourceService";

import { getNetworks } from "@/services/generalService";
import { ApiTransactionsPayload } from "@/types/report.type";
import { NetworksData, Source, SourceGroup } from "@/types/sources.type";

import { create } from "zustand";
import useWorkspaceStore from "./workspaces.store";

type SourcesState = {
  loading: boolean;
  sourceGroups: SourceGroup[];
  sources: Source[];
  clearSourceEntries: () => void;
  networks: NetworksData;
  setNetworks: (networks: NetworksData) => void;
  selectedSourceGroupId: string;
  selectedSourceGroup: SourceGroup | null;
  source: Source | null;
  setSelectedSourceGroup: (sourceGroupId: string) => void;
  setSelectedSourceGroupData: (sourceGroup: SourceGroup) => void;
  setSelectedSource: (sourceGroup: Source) => void;
  fetchAllSourceGroups: (
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<SourceGroup>>;
  fetchSourceEntriesForSelectedGroup: (
    sourceGroupId: string,
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<Source>>;
  uploadSource: (file: string, sourceGroupId: string) => Promise<void>;
  downloadTemplate: (type: string) => Promise<string>;
  createSourceGroup: (
    workspaceId: string,
    sourceName: string,
    groupType: string
  ) => Promise<string | null>;
  editSourceGroup: (sourceGroupId: string, sourceName: string) => Promise<void>;
  createSourceEntry: (
    sourceGroupId: string,
    entryData: any,
    entryType: string
  ) => Promise<void>;
  editSourceEntry: (sourceId: string, entryData: any) => Promise<void>;
  deleteSourceEntry: (sourceId: string) => Promise<void>;
  downloadSourceGroup: (sourceGroupId: string) => Promise<void>;
  selectSourceGroup: (sourceGroupId: string) => Promise<void>;
  fetchNetworks: () => Promise<void>;
};

const useSourcesStore = create<SourcesState>((set, get) => ({
  loading: false,
  sourceGroups: [],
  sources: [],
  source: null,
  clearSourceEntries: () => set({ sources: [] }),
  networks: {} as NetworksData,
  setNetworks: (networks: NetworksData) => set({ networks }),

  selectedSourceGroupId: "",
  selectedSourceGroup: null,

  fetchAllSourceGroups: async (
    limit: number = 100,
    page: number = 1
  ): Promise<ApiTransactionsPayload<SourceGroup>> => {
    const defaultPayload: ApiTransactionsPayload<SourceGroup> = {
      page,
      limit,
      pages: 0,
      max: 0,
      data: [],
    };
    set({ loading: true });
    const workspace = useWorkspaceStore.getState().getSelectedWorkspace();
    const workspaceId = workspace?.workspaceId || "";

    if (!workspaceId) {
      set({ loading: false });
      return defaultPayload;
    }

    try {
      const response = await getAllSourceGroups(workspaceId, limit, page);
      set({
        sourceGroups: response.data.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.pages || 0,
        max: response.data.max || 0,
        data: response.data.data.data,
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching source groups:", error);
      return defaultPayload;
    }
  },

  setSelectedSourceGroup: (sourceGroupId) => {
    set({
      selectedSourceGroupId: sourceGroupId,
    });
    get().fetchSourceEntriesForSelectedGroup(sourceGroupId, 10, 1);
  },

  setSelectedSourceGroupData: (sourceGroup) => {
    set({
      selectedSourceGroup: sourceGroup,
      selectedSourceGroupId: sourceGroup.sourceGroupId,
    });
  },
  setSelectedSource: (source: Source) => {
    set({
      source: source,
    });
  },
  uploadSource: async (file, sourceGroupId) => {
    console.log("Uploading source:", file, sourceGroupId);
    await uploadSource(file, sourceGroupId);
    await get().fetchAllSourceGroups(10, 1);
    get().setSelectedSourceGroup(sourceGroupId);
    await get().fetchSourceEntriesForSelectedGroup(sourceGroupId, 10, 1);
  },

  fetchSourceEntriesForSelectedGroup: async (
    sourceGroupId: string,
    limit: number = 100,
    page: number = 1
  ): Promise<ApiTransactionsPayload<Source>> => {
    set({ loading: true });

    const defaultPayload: ApiTransactionsPayload<Source> = {
      page,
      limit,
      pages: 0,
      max: 0,
      data: [],
    };

    try {
      const response = await getSourcesPage(sourceGroupId, limit, page);
      set({
        sources: response.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.pages || 0,
        max: response.data.max || 0,
        data: response.data.data.data,
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching entries:", error);
      return defaultPayload; // Return default payload on error
    }
  },

  downloadTemplate: async (type) => {
    const response = await downloadTemplate(type);
    return response.data;
  },
  createSourceGroup: async (workspaceId, sourceName, groupType) => {
    try {
      set({ loading: true });
      const response = await createSourceGroup(
        workspaceId,
        sourceName,
        groupType
      );
      const createdSourceGroupId = response && response.data.sourceGroupId;
      set({ loading: false });
      set({
        selectedSourceGroupId: createdSourceGroupId,
      });

      return createdSourceGroupId;
    } catch (error) {
      set({ loading: false });
      console.error("Error creating source group:", error);
      return;
    }
  },

  editSourceGroup: async (sourceGroupId, sourceName) => {
    try {
      set({ loading: true });
      await editSourceGroup(sourceGroupId, sourceName);
      await get().fetchAllSourceGroups(100, 1);
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error("Error editing source group:", error);
    }
  },

  createSourceEntry: async (sourceGroupId, entryData, entryType) => {
    try {
      set({ loading: true });
      await createSourceEntry(sourceGroupId, entryData, entryType);
      await get().fetchSourceEntriesForSelectedGroup(sourceGroupId, 100, 1);
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error("Error creating source entry:", error);
    }
  },
  editSourceEntry: async (sourceId, entryData) => {
    try {
      set({ loading: true });
      await editSourceEntry(sourceId, entryData);
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error("Error editing source entry:", error);
    }
  },

  deleteSourceEntry: async (sourceId) => {
    try {
      set({ loading: true });
      await deleteSourceEntry(sourceId);
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error("Error deleting source entry:", error);
    }
  },
  downloadSourceGroup: async (sourceGroupId) => {
    try {
      set({ loading: true });
      await downloadSourceGroup(sourceGroupId);
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error("Error downloading source group:", error);
    }
  },

  selectSourceGroup: async (sourceGroupId) => {
    try {
      set({ loading: true });
      await selectSourceGroup(sourceGroupId);
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error("Error selecting source group:", error);
    }
  },

  fetchNetworks: async () => {
    try {
      const response = await getNetworks();
      set({ networks: response.data.data as NetworksData });
    } catch (error) {
      console.error("Error fetching networks:", error);
    }
  },
}));

const fetchNetworks = async () => {
  try {
    const response = await getNetworks();
    useSourcesStore.getState().setNetworks(response.data.data as NetworksData);
  } catch (error) {
    console.error("Error fetching networks:", error);
  }
};

fetchNetworks();

export default useSourcesStore;
