import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "@/components/ui/use-toast";
import {
  deleteSourceEntry,
  downloadSourceGroup,
} from "@/services/sourceService";
import useSourcesStore from "@/stores/sources.store";
import { Source, SourceGroup } from "@/types/sources.type";
import AddressCell from "@/components/misc/AddressCell";
import useWorkspaceStore from "@/stores/workspaces.store";

interface MultiStepContextType {
  sourceGroups: SourceGroup[];
  sourceEntries: Source[];
  step: number;
  colsMap: { [key: string]: { id: string; name: string }[] };
  filtersMap: { [key: string]: { accessorKey: string; placeholder: string } };
  currentPage: number;
  loading: boolean;
  pageSize: number;
  totalRows: number;
  totalPages: number;
  setPageSize: (size: number) => void;
  setCurrentPage: (page: number) => void;
  setTotalPages: (pages: number) => void;
  setTotalRows: (rows: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  createSourceGroupForWorkspace: (
    data: Omit<SourceGroup, "sourceGroupId">
  ) => Promise<SourceGroup | null>;
  addSourceEntry: (entryData: Omit<Source, "entryId">) => void;
  fetchSourceEntries: (
    sourceGroupId: string,
    page: number,
    limit: number
  ) => Promise<void>;
  deleteEntry: (entryId: string) => Promise<void>;
  downloadSourceAsCSV: (sourceGroupId: string) => Promise<void>;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (size: number) => void;
}

export const MultiStepContext = createContext({} as MultiStepContextType);

interface MultiStepContextProviderProps {
  children: ReactNode;
}

export function MultiStepContextProvider({
  children,
}: MultiStepContextProviderProps) {
  const { toast } = useToast();
  const [step, setStep] = useState<number>(1);
  const [sourceGroups, setSourceGroups] = useState<SourceGroup[]>([]); // List of source groups
  const [sourceEntries, setSourceEntries] = useState<Source[]>([]); // List of source entries
  const [currentPage, setCurrentPage] = useState<number>(1);
  useState<SourceGroup | null>(null); // Selected source group
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(1);
  const workspaceId = useWorkspaceStore((state) => state.selectedWorkspaceId);
  const { createSourceGroup, setSelectedSourceGroupData, loading } =
    useSourcesStore();

  const colsBALA = [
    {
      id: "entry.address",
      name: "Address",
      width: 200,
    },
    {
      id: "entry.network",
      name: "Network",
      width: 200,
    },
    {
      id: "entry.asset",
      name: "Asset",
      width: 200,
    },
  ];

  const colsEXCH = [
    {
      id: "entry.network",
      name: "Exchange",
      width: 200,
    },
    {
      id: "entry.secret",
      name: "Secret",
      width: 300,
    },
    {
      id: "entry.key",
      name: "Key",
      width: 300,
    },
  ];

  const colsPUBK = [
    {
      id: "entry.network",
      name: "Network",
      width: 150,
    },
    {
      id: "entryType",
      name: "Address Type",
      width: 200,
    },
    {
      id: "entry.keys.0",
      name: "xPub Key",
      width: 300,
    },
    {
      id: "entry.keys.length",
      name: "Count",
      width: 150,
    },
  ];

  const colsRISK = [
    {
      id: "entry.address",
      name: "Address",
      width: 650,
    },
    {
      id: "entry.network",
      name: "Network",
      width: 150,
    },
  ];

  const colsSIGN = [
    {
      id: "entry.network",
      name: "Network",
      width: 150,
    },
    {
      id: "entry.pubkey",
      name: "Public Key",
      width: 400,
    },
    {
      id: "entry.signature",
      name: "Signature",
      width: 300,
      cell: (row: string) => <AddressCell fullAddress={row} />,
    },
  ];

  const colsMap: { [key: string]: { id: string; name: string }[] } = {
    BALA: colsBALA,
    EXCH: colsEXCH,
    PUBK: colsPUBK,
    RISK: colsRISK,
    SIGN: colsSIGN,
  };

  const filtersMap: {
    [key: string]: { accessorKey: string; placeholder: string };
  } = {
    BALA: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
    EXCH: { accessorKey: "entry.network", placeholder: "Filter Exchanges..." },
    PUBK: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
    RISK: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
    SIGN: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
  };

  async function createSourceGroupForWorkspace(
    data: Omit<SourceGroup, "sourceGroupId">
  ) {
    let newSourceGroup: SourceGroup = {
      sourceGroupId: "",
      sourceName: data.sourceName,
      groupType: data.groupType,
      count: 0,
      updated: Number(new Date()),
    };

    try {
      const newSourceGroupId = await createSourceGroup(
        workspaceId,
        data.sourceName,
        data.groupType
      );
      console.log("Source group created successfully! ", newSourceGroupId);

      newSourceGroup = {
        ...newSourceGroup,
        sourceGroupId: newSourceGroupId ? newSourceGroupId : "",
      };
      setSelectedSourceGroupData(newSourceGroup);
      return newSourceGroup;
    } catch (error) {
      console.error("Error creating source group:", error);
      return null;
    }
  }
  function addSourceEntry(entryData: Omit<Source, "sourceId" | "updated">) {
    const newEntry: Source = {
      sourceId: uuidv4(),
      sourceGroupId: entryData.sourceGroupId,
      entry: entryData.entry,
      updated: 0,
    };

    setSourceEntries([...sourceEntries, newEntry]);

    setSourceGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.sourceGroupId === entryData.sourceGroupId
          ? { ...group, count: group.count + 1 }
          : group
      )
    );
  }

  const [getSourceEntriesForSourceGroup] = useSourcesStore((state) => [
    state.fetchSourceEntriesForSelectedGroup,
  ]);

  const fetchSourceEntries = useCallback(
    async (sourceGroupId: string, page: number, limit: number) => {
      try {
        const response = await getSourceEntriesForSourceGroup(
          sourceGroupId,
          limit,
          page
        );
        if (response && response.data && response.data.length > 0) {
          setSourceEntries(response.data);
          setTotalPages(response.pages);
          setTotalRows(response.max);
          if (response.limit) setPageSize(response.limit);
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch source entries",
          variant: "destructive",
        });
      }
    },
    [getSourceEntriesForSourceGroup, toast]
  );

  const deleteEntry = async (entryId: string) => {
    try {
      await deleteSourceEntry(entryId);
      setSourceEntries((prevEntries) =>
        prevEntries.filter((entry) => entry.sourceId !== entryId)
      );
      toast({
        title: "Entry deleted",
        description: "Source entry was deleted successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete source entry",
        variant: "destructive",
      });
    }
  };

  const downloadSourceAsCSV = async (sourceGroupId: string) => {
    try {
      await downloadSourceGroup(sourceGroupId); // Download CSV from API
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to download source data",
        variant: "destructive",
      });
    }
  };

  function nextStep() {
    if (step === 5) return; // Optional step limit
    setStep((prev) => prev + 1);
  }

  function prevStep() {
    if (step === 1) return;
    setStep((prev) => prev - 1);
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(!isNaN(page) ? page : 1);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(!isNaN(size) && size > 0 ? size : 100);
  };

  return (
    <MultiStepContext.Provider
      value={{
        sourceGroups,
        loading,
        sourceEntries,
        step,
        colsMap,
        filtersMap,
        currentPage,
        totalPages,
        totalRows,
        pageSize,
        setPageSize,
        setTotalPages,
        setTotalRows,
        setCurrentPage,
        nextStep,
        prevStep,
        createSourceGroupForWorkspace,
        addSourceEntry,
        fetchSourceEntries,
        deleteEntry,
        downloadSourceAsCSV,
        handlePageChange,
        handlePageSizeChange,
      }}
    >
      {children}
    </MultiStepContext.Provider>
  );
}

export const useMultiContext = () => useContext(MultiStepContext);
