import { downloadSourceGroup } from "@/services/sourceService";
import { ArrowLeft, BarChart4Icon, Download } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { downloadCSV, getNameBySymbol } from "@/lib/utils";
import useSourcesStore from "@/stores/sources.store";
import { validateAndCleanFile } from "@/zod/source.zod";

function StepFour() {
  const { sourceGroupId } = useParams<{ sourceGroupId: string }>();
  const navigate = useNavigate();

  const { selectedSourceGroup } = useSourcesStore();

  const downloadSource = async () => {
    if (!sourceGroupId) return;
    const response = await downloadSourceGroup(sourceGroupId);
    const cleanCSV = validateAndCleanFile(
      response.data.data,
      selectedSourceGroup?.groupType || ""
    );
    downloadCSV(cleanCSV, `${selectedSourceGroup?.sourceName}.csv`);
  };

  const runReport = async () => {
    navigate(`/reports/run/${sourceGroupId}`);
  };

  return (
    <div className="flex flex-wrap flex-col p-4 mb-2 bg-white shadow rounded-lg max-w-6xl w-full mx-auto dark:bg-gray-800">
      <div>
        <span className="text-xl font-semibold text-gray-800">
          Source: {selectedSourceGroup?.sourceName}
        </span>
        <span className="text-gray-600 block text-sm">
          ID: {selectedSourceGroup?.sourceGroupId}
        </span>
      </div>
      <div className="flex items-center space-x-4 m-2">
        <span className="text-md font-semibold">Source Type:</span>
        <span className="text-gray-700 dark:text-gray-300 block text-sm border rounded-full border-gray-300 px-4 py-2">
          {getNameBySymbol(selectedSourceGroup?.groupType || "")}
        </span>
      </div>

      <div className="col-span-2 pl-2 flex ">
        <Button
          className="w-full bg-primary"
          variant="outline"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Sources
        </Button>
        <Button
          className="w-full ml-2 bg-primary"
          variant="outline"
          onClick={downloadSource}
          disabled={selectedSourceGroup?.count === 0}
        >
          <Download className="h-4 w-4 mr-2" />
          Download CSV
        </Button>
        <Button
          className="w-full ml-2 bg-primary border"
          variant="outline"
          onClick={runReport}
          disabled={selectedSourceGroup?.count === 0}
        >
          <BarChart4Icon className="h-4 w-4 mr-2" />
          Run Report
        </Button>
      </div>
    </div>
  );
}

export default StepFour;
