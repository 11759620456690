import { StepItem } from "./StepItem";

const stepData = [
  { num: 1, title: "Create Source", description: "Create Source" },
  { num: 2, title: "Add Source Entries", description: "Add Source Entries" },
  { num: 3, title: "Review", description: "Preview" },
  {
    num: 4,
    title: "Next Steps",
    description: "Either run a report or stop here.",
  },
];

export function Steps() {
  return (
    <div className="relative flex items-center justify-center lg:justify-between p-6 lg:pt-10">
      <ul className="relative flex w-full justify-between items-center gap-8 lg:gap-16">
        {stepData.map((info, index) => (
          <li
            key={info.num}
            className="relative flex flex-col items-center flex-1"
          >
            {index < stepData.length - 1 && (
              <span className="absolute top-1/4 left-full w-full h-1 bg-gray-300 transform lg:w-[100%] lg:-translate-x-20 z-0"></span>
            )}
            <StepItem infos={info} />
          </li>
        ))}
      </ul>
    </div>
  );
}
