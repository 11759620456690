/* eslint-disable @typescript-eslint/no-explicit-any */
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  ADDRESS_TYPES,
  BLOCKCHAIN_NETWORKS,
  EXCHANGE_NETWORKS,
  LOADING_TEXT,
} from "@/config/general";
import { createSourceEntry, editSourceEntry } from "@/services/sourceService";
import React, { FC, useEffect, useState } from "react";
interface Asset {
  value: string;
  img: string;
  label: string;
}

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { getNameBySymbol } from "@/lib/utils";
import useAuthStore from "@/stores/auth.store";
import useSourcesStore from "@/stores/sources.store";
import { getSchemasBySourceType } from "@/zod/source.zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import NetworkSelect from "../ledgernalysis/NetworkSelect";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const formFieldMappings: any = {
  BALA: ["network", "asset", "address"],
  EXCH: ["network", "secret", "key", "passphrase"],
  RISK: ["network", "address"],
  SIGN: ["network", "message", "signature", "pubkey"],
  PUBK: ["network", "addresstype", "xpubkey", "numberofaddresses"],
};
function updateFormFieldMappings(
  networksData: any,
  sourceType: string,
  network: string
) {
  if (sourceType === "EXCH") {
    const requirements =
      networksData?.types[sourceType]?.[network]?.requirements || [];

    if (!Array.isArray(requirements)) {
      console.error("Requirements is not an array:", requirements);
      return;
    }
    const formFieldMappingsValidated = { ...formFieldMappings };
    formFieldMappingsValidated[sourceType] = ["network", ...requirements];
    return formFieldMappingsValidated;
  }

  return formFieldMappings;
}

function getDefaultValues(
  sourceType: string,
  sourceEntry: any,
  formFieldMappings: any
) {
  const fieldNames = formFieldMappings[sourceType] || [];
  const defaultValues: any = {};

  fieldNames.forEach((fieldName: string) => {
    defaultValues[fieldName] = sourceEntry?.[fieldName] || "";
  });

  return defaultValues;
}
interface SourceEntriesFormProps {
  entryId?: string | null;
  sourceType: string;
  setIsDialogOpen?: (value: boolean) => void;
}

export const SourceEntriesForm: FC<SourceEntriesFormProps> = ({
  entryId,
  sourceType,
  setIsDialogOpen,
}) => {
  const { toast } = useToast();

  const { source } = useSourcesStore();

  const [networks] = useSourcesStore((state) => [state.networks]);
  const sourceEntry = source && source.entry;
  const [assetsForNetwork, setAssetsForNetwork] = useState<Asset[]>([]);
  const [isFieldsEnabled, setIsFieldsEnabled] = useState<boolean>(true);
  const [dynamicFormFields, setDynamicFormFields] =
    useState<any>(formFieldMappings);

  const [defaultValues, setDefaultValues] = useState<any>(
    getDefaultValues(sourceType, sourceEntry, dynamicFormFields)
  );

  const fetchAssetsForNetwork = async (network: string) => {
    setDynamicFormFields(
      updateFormFieldMappings(networks, sourceType, network)
    );
    setDefaultValues(
      getDefaultValues(sourceType, sourceEntry, dynamicFormFields)
    );
    setIsFieldsEnabled(true);
    if (sourceType !== "BALA") return;
    try {
      const networkRef = networks[sourceType].find(
        (item: any) => item.symbol === network
      );

      const assets = [
        ...(networkRef?.native
          ? [
              {
                value: networkRef.native.asset,
                img: networkRef.native.icon,
                label: networkRef.native.name,
              },
            ]
          : []),
        ...(networkRef?.tokens || []).map((token: any) => ({
          value: token.asset,
          img: token.icon,
          label: token.name,
        })),
        ...(networkRef?.nfts || []).map((nft: any) => ({
          value: nft.asset,
          img: nft.icon,
          label: nft.name,
        })),
      ];
      setAssetsForNetwork(assets);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const email = useAuthStore((state) => state.user?.email);
  const sourceGroupId = useSourcesStore((state) => state.selectedSourceGroupId);

  const schema: any = getSchemasBySourceType(sourceType);

  const sourceForm = useForm<SourceFormType>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (entryId && sourceEntry) {
      sourceForm.reset(sourceEntry || {});
    }
  }, [entryId, sourceEntry, sourceForm]);

  useEffect(() => {
    if (sourceEntry?.network) {
      fetchAssetsForNetwork(sourceEntry?.network);
    }
  }, [sourceEntry]);

  type SourceFormType = z.infer<typeof schema>;

  const extractFields = (data: any, fields: string[]) => {
    return fields.reduce((acc: any, field: string) => {
      acc[field] = data[field];
      return acc;
    }, {});
  };

  const onSubmit = async (data: SourceFormType) => {
    try {
      setLoading(true);
      if (!email) {
        return;
      }

      let response: any, sourceId: any;
      const fields = dynamicFormFields[sourceType] || [];
      const submissionData = extractFields(data, fields);

      if (entryId) {
        sourceId = entryId;
        response = await editSourceEntry(sourceId, submissionData);
      } else {
        response = await createSourceEntry(
          sourceGroupId,
          submissionData,
          sourceType
        );
        console.log("Response", response);
      }

      setIsDialogOpen && setIsDialogOpen(false);
      toast({
        title: `Entry ${entryId ? "Updated" : "Created"}`,
        description: entryId
          ? "Your source entry has been updated."
          : "Your source entry has been created.",
      });
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const networksMaster =
    sourceType === "EXCH"
      ? EXCHANGE_NETWORKS
      : ["RISK", "PUBK"].indexOf(sourceType) > -1
        ? BLOCKCHAIN_NETWORKS.filter((network) => network.value === "BTC")
        : sourceType === "SIGN"
          ? BLOCKCHAIN_NETWORKS.filter((network) => network.value === "ETH")
          : BLOCKCHAIN_NETWORKS;

  const renderFormField = (fieldType: string) => {
    switch (fieldType) {
      case "address":
        return (
          <FormField
            control={sourceForm.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Address"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "secret":
        return (
          <FormField
            control={sourceForm.control}
            name="secret"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Secret</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Secret"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "passphrase":
        return (
          <FormField
            control={sourceForm.control}
            name="passphrase"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Passphrase</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Passphrase"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "key":
        return (
          <FormField
            control={sourceForm.control}
            name="key"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Key/Client ID</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Key/Client ID"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "network":
        return (
          <FormField
            control={sourceForm.control}
            name="network"
            render={({ field }) => {
              const handleNetworkChange = async (newValue: string) => {
                await fetchAssetsForNetwork(newValue);
                field.onChange(newValue);
              };
              return (
                <FormItem className="w-full">
                  <FormLabel>Network</FormLabel>
                  <div className="my-4">
                    <NetworkSelect
                      networks={networksMaster}
                      onValueChange={handleNetworkChange}
                      title="Select Network"
                      description="Choose one of the available networks."
                      initialValue={
                        (sourceEntry && sourceEntry?.network) || " "
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        );
      case "asset":
        return (
          <FormField
            control={sourceForm.control}
            name="asset"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Asset</FormLabel>
                <div className="my-4">
                  <NetworkSelect
                    networks={assetsForNetwork}
                    onValueChange={field.onChange}
                    title="Select Asset"
                    description="Choose one of the available assets."
                    initialValue={(sourceEntry && sourceEntry?.asset) || " "}
                  />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "message":
        return (
          <FormField
            control={sourceForm.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Message"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "signature":
        return (
          <FormField
            control={sourceForm.control}
            name="signature"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Signature</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Signature"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "pubkey":
        return (
          <FormField
            control={sourceForm.control}
            name="pubkey"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Public Key</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Public Key"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "addresstype":
        return (
          <FormField
            control={sourceForm.control}
            name="addresstype"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address Type</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {ADDRESS_TYPES.map((type, i) => (
                      <SelectItem value={type.value} key={i}>
                        {type.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "xpubkey":
        return (
          <FormField
            control={sourceForm.control}
            name="xpubkey"
            render={({ field }) => (
              <FormItem>
                <FormLabel>xPub Key</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter xPub Key"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case "numberofaddresses":
        return (
          <FormField
            control={sourceForm.control}
            name="numberofaddresses"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Number of Addresses</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Number of Addresses"
                    {...field}
                    disabled={!isFieldsEnabled}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
    }
  };

  return (
    <>
      <div className="flex items-center space-x-4 pt-4">
        <span className="text-md font-semibold">Source Type:</span>
        <span className="text-gray-700 block text-sm border border-gray-300 px-4 py-2 rounded-full dark:text-gray-300">
          {getNameBySymbol(sourceType)}
        </span>
      </div>
      <div className="mt-4">
        {error && <div className="alert alert-danger">{error}</div>}
      </div>

      <Form {...sourceForm}>
        <form
          onSubmit={sourceForm.handleSubmit(onSubmit)}
          className="space-y-8"
        >
          {dynamicFormFields[sourceType]?.map((fieldType: string) => (
            <React.Fragment key={fieldType}>
              {renderFormField(fieldType)}
            </React.Fragment>
          ))}
          {loading ? (
            <Button disabled className="w-full" size="lg">
              <span className="loading-icon" /> {/* Add loading icon */}
              {LOADING_TEXT}
            </Button>
          ) : (
            <Button type="submit" className="w-full" size="lg">
              {entryId ? "Update Entry" : "Create Entry"}
            </Button>
          )}
        </form>
      </Form>
    </>
  );
};
