import { FC } from "react";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface GeneralPopoverProps {
  title: string;
  description: string;
  children: React.ReactNode;
  isEditPopoverOpen: boolean;
  setIsEditPopoverOpen: (value: boolean) => void;
  triggerText: string;
}

export const GeneralPopover: FC<GeneralPopoverProps> = ({
  title,
  description,
  children,
  isEditPopoverOpen,
  setIsEditPopoverOpen,
  triggerText,
}) => {
  return (
    <Popover open={isEditPopoverOpen} onOpenChange={setIsEditPopoverOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline">{triggerText}</Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">{title}</h4>
            <p className="text-sm text-muted-foreground">{description}</p>
          </div>
          <div className="grid gap-2">{children}</div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
