import { FC, useState } from "react";
import { Button } from "@/components/ui/button";
import { LOADING_TEXT } from "@/config/general";
import { getNameBySymbol } from "@/lib/utils";
import useSourcesStore from "@/stores/sources.store";
import { validateAndCleanFile } from "@/zod/source.zod";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";
import { Download, File, UploadCloud } from "lucide-react";
import { useDropzone } from "react-dropzone";
import { useToast } from "@/components/ui/use-toast";

interface SourceUploadFormProps {
  sourceGroupId: string;
  onClose?: () => void;
}

export const SourceUploadForm: FC<SourceUploadFormProps> = ({
  sourceGroupId,
  onClose,
}) => {
  const { uploadSource, downloadTemplate } = useSourcesStore();

  const { selectedSourceGroup } = useSourcesStore();
  const [fileName, setFileName] = useState("");
  const [textData, setTextData] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [templateLoading, setTemplateLoading] = useState(false);
  const { toast } = useToast();

  const handleFileChange = (files: File[]) => {
    const file = files[0];
    setFileName(file?.name || "");
    const reader = new FileReader();
    reader.onload = (e) => setTextData(e.target?.result as string);
    file && reader.readAsText(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileChange,
    accept: { "text/csv": [".csv"] },
  });

  const GROUP_TYPE = selectedSourceGroup
    ? selectedSourceGroup?.groupType
    : "BALA";

  const downloadTemplateFile = async () => {
    setTemplateLoading(true);
    try {
      const templateResponse: any = await downloadTemplate(GROUP_TYPE);
      const blob = new Blob([templateResponse.data], {
        type: "text/csv;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `defaultCSV_${GROUP_TYPE}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } finally {
      setTemplateLoading(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const cleanCSV = validateAndCleanFile(
        textData.replace(/\r/g, ""),
        selectedSourceGroup ? selectedSourceGroup?.groupType : "BALA"
      );
      await uploadSource(cleanCSV, sourceGroupId);
      toast({ title: "Success", description: "Source uploaded successfully" });
      onClose?.();
    } catch (e: any) {
      const errMessage = e?.response?.data?.errors[0] || e.message;
      setError(
        errMessage.includes("Some entries not inserted.") ? "" : errMessage
      );
      if (!errMessage.includes("Some entries not inserted.")) onClose?.();
    } finally {
      setLoading(false);
    }
  };

  if (!selectedSourceGroup) return null;

  return (
    <div>
      <div className="flex items-center space-x-4">
        <span className="font-semibold text-sm">Source Type:</span>
        <span className="text-gray-700 block text-sm border border-gray-300 px-2 py-1 rounded-lg">
          {getNameBySymbol(selectedSourceGroup?.groupType)}
        </span>
        <Button
          className="w-full"
          variant="outline"
          disabled={templateLoading}
          onClick={downloadTemplateFile}
        >
          {templateLoading ? (
            <ReloadIcon className="animate-spin" />
          ) : (
            <Download className="h-4 w-4 mr-2" />
          )}
          Download template
        </Button>
      </div>

      <div className="py-4">
        <Label htmlFor="file">Upload CSV</Label>
        <div
          className="flex items-center justify-center w-full text-gray-500"
          {...getRootProps()}
        >
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          >
            {isDragActive ? (
              <p>Select file</p>
            ) : fileName ? (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <File className="w-8 h-8 mb-4" />
                <p className="mb-2 text-sm">{fileName}</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <UploadCloud className="w-8 h-8 mb-4" />
                <p className="mb-2 text-sm">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs">CSV files only</p>
              </div>
            )}
            <input {...getInputProps()} />
          </label>
        </div>
      </div>

      {error && (
        <div className="text-red-500 text-sm font-semibold text-center">
          {error}
        </div>
      )}

      <Button
        className="w-full mt-4"
        size="lg"
        onClick={onSubmit}
        disabled={loading}
      >
        {loading ? (
          <>
            <span className="loading-icon" /> {LOADING_TEXT}
          </>
        ) : (
          "Upload"
        )}
      </Button>
    </div>
  );
};
